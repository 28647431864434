<template>
  <div class="sb-wrapper">
    <div
      class="u-relative selection__box"
      tabindex="-1"
      @blur="hideDropdown"
      @click="toggleDropdown(!showDropdown, 111)"
    >
      <!-- Tab -->
      <label
        for="selection-input"
        class="u-absolute selection__tab cursor-pointer"
        :class="{ active: showDropdown, dropdown: type === 'dropdown' }"
      >
        <span class="fs-14 selection__label">
          <span v-if="!selectedOptions.length">{{ label }}</span>
          <span class="selection__label" v-if="selectedOptions.length">
            {{ selectedOptions.length }} selected</span
          ></span
        >
      </label>
      <!-- Input -->
      <input
        class="u-absolute selection__input"
        :class="{ hidden: type !== 'search-box' }"
        id="selection-input"
        autocomplete="off"
        :placeholder="label"
        v-model="inputText"
        @blur="toggleDropdown(false, 200)"
        @keypress.enter="addToOption"
      />

      <!-- Options -->
      <div
        v-if="filteredOptions.length"
        class="selection__options"
        :class="{ active: showDropdown }"
      >
        <p
          class="selection__options-list cursor-pointer flex flex__item-center"
          v-for="(option, index) in allOptions"
          :key="index"
          @click="selectOption(option)"
        >
          <input type="checkbox" :id="`check-${index + 1}`" hidden />
          <label
            :for="`check-${index + 1}`"
            class="selection__option-checkbox flex flex__item-center flex__center"
          >
            <div
              class="selection__checkbox-icon"
              v-if="!!selectedOptions.find((opt) => opt.name === option.name)"
            >
              <img src="../../assets/icons/check.svg" alt="check" />
            </div>
          </label>
          <span class="fs-14 text">{{ option.name }}</span>
        </p>
      </div>
      <div v-else class="selection__options" :class="{ active: showDropdown }">
        <p
          class="selection__options-list cursor-pointer flex flex__item-center"
          v-for="(option, index) in allOptions"
          :key="index"
          @click="selectOption(option)"
        >
          <input type="checkbox" :id="`check-${index + 1}`" hidden />
          <label
            :for="`check-${index + 1}`"
            class="selection__option-checkbox flex flex__item-center flex__center"
          >
            <div
              class="selection__checkbox-icon"
              v-if="!!selectedOptions.find((opt) => opt.name === option.name)"
            >
              <img src="../../assets/icons/check.svg" alt="check" />
            </div>
          </label>
          <span class="fs-14 text">{{ option.name }}</span>
        </p>
      </div>
    </div>

    <div
      class="selection__boxes-wrapper flex flex__wrap"
      v-if="selectedOptions.length"
    >
      <div
        v-for="(option, index) in selectedOptions"
        :key="index"
        class="selection__boxes text-capitalize flex flex__item-center"
        :class="[selectionBoxType]"
      >
        <p class="text">{{ option.name }}</p>
        <button @click="removeOption(option)" class="flex cursor-pointer">
          <img src="../../assets/icons/close-icon-2.svg" alt="close-icon" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectionBox',
  props: {
    label: {
      type: String,
      default: 'Label'
    },
    type: {
      type: String,
      default: 'dropdown',
      validator: (value) => ['dropdown', 'search-box'].includes(value)
    },
    options: {
      type: Array,
      default: () => []
    },
    selectionBoxType: {
      type: String,
      default: ''
    },
    defaultValue: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showDropdown: false,
      selectedOptions: [],
      inputText: '',
      dropdownOptions: [...this.options],
      selecting: false,
      timer: 0
    };
  },
  created() {
    if (this.defaultValue.length) {
      this.defaultValue.map((option) => this.selectOption(option));
    }
  },
  computed: {
    filteredOptions() {
      return this.dropdownOptions.filter(
        (option) =>
          !!option.name.toLowerCase().match(this.inputText.toLowerCase())
      );
    },
    allOptions() {
      return this.filteredOptions.length ? this.filteredOptions : this.options;
    }
  },
  methods: {
    hideDropdown() {
      console.log('hiding...');
      setTimeout(() => {
        this.showDropdown = false;
      }, 222);
    },
    toggleDropdown(value, delay = 0) {
      console.log('Toggling...');
      setTimeout(() => {
        if (this.selecting) {
          return;
        } else {
          setTimeout(() => (this.showDropdown = value), delay);
        }
      }, 111);
    },
    selectOption(option) {
      this.selecting = true;
      this.timer = setTimeout(() => {
        this.selecting = false;
      }, 555);

      if (
        this.selectedOptions.findIndex(
          (opt) => opt.name.toLowerCase() === option.name.toLowerCase()
        ) === -1
      ) {
        this.selectedOptions = [...this.selectedOptions, option];

        return;
      }

      const optionObj = this.selectedOptions.find(
        (opt) => opt.name === option.name
      );
      this.selectedOptions = this.selectedOptions.filter(
        (opt) => opt.name !== optionObj.name
      );
    },
    removeOption(option) {
      this.selectedOptions = this.selectedOptions.filter(
        (opt) => opt.name !== option.name
      );
    },
    addToOption() {
      const option = {
        name: this.inputText,
        label: this.inputText.split(' ').join('_')
      };
      if (
        this.dropdownOptions.findIndex(
          (option) => option.name === this.inputText
        ) === -1
      ) {
        this.selectedOptions = [...this.selectedOptions, option];

        this.dropdownOptions = [...this.dropdownOptions, option];
        this.inputText = '';

        if (this.selecting) {
          return;
        }
        this.showDropdown = false;
      }
    }
  },
  watch: {
    selectedOptions(selected) {
      this.$emit('selected', selected);
    }
  }
};
</script>

<style lang="scss" scoped>
@use './selectionBox.scss' as *;

.selection__box:focus {
  outline: 0;
}

.selection__options {
}

.sb-wrapper:focus {
  border: 0;
  outline: 0;
}
</style>
