<template>
  <div class="sub-page pt-2">
    <h2>Experience and Skills</h2>
    <hr class="mt-2" />
    <div class="mt-4">
      <h2 class="text__mid-black fs-24 fw-600 mb-3">Key Skills</h2>

      <div class="grid grid__layout mb-3">
        <div class="col-12 z-22">
          <selection-box
            label="Type in to search or add new"
            :options="skills"
            type="search-box"
            @selected="updateSelection('skills', $event)"
            :defaultValue="keySkills.skills"
          />
        </div>
        <!-- <div class="col-12 u-above z-21">
          <selection-box
            @selected="updateSelection('job_type', $event)"
            label="Choose job type"
            :options="jobTypes"
            :defaultValue="keySkills.job_type"
          />
        </div>
        <div class="col-12 u-above">
          <selection-box
            label="Choose work type"
            :options="workTypes"
            @selected="updateSelection('workType', $event)"
            :defaultValue="keySkills.workType"
          />
        </div> -->
      </div>

      <div class="flex flex-end mb-3">
        <Button
          :disabled="!keySkills.skills.length || updatingSkills"
          :loading="updatingSkills"
          class="w-20"
          theme="blue"
          @click="updateKeySkills"
          >Save Changes</Button
        >
      </div>
    </div>

    <div>
      <h2 class="text__mid-black fs-24 fw-600 mb-3">Experience</h2>

      <Accordion
        type="secondary"
        :title="experience.companyName"
        class="mb-3"
        v-for="(experience, index) in experiences"
        :key="index"
      >
        <ValidationObserver v-slot="{ invalid }">
          <div class="grid grid__layout grid__mobile gap-2 pb-3">
            <Input
              class="col-6 m-col-12"
              type="text"
              placeholder="Company Name"
              label="Company Name"
              v-model="experience.companyName"
              name="Company Name"
              rules="required"
            />
            <div class="col-6 m-col-12 z-27">
              <Input
                label="Position"
                type="text"
                placeholder="Position"
                v-model="experience.jobTitle"
                name="Position"
                rules="required"
              />
            </div>
            <div class="col-6 m-col-12 z-26">
              <label class="fs-14 fw-600 text__dark-grey mb-1x">
                Start Date
              </label>
              <Datepicker
                v-model="experience.startDate"
                name="Start Date"
                placeholder="Start Date"
                :disabled-dates="disableFuture"
              />
            </div>
            <div class="col-6 m-col-12 z-25">
              <template v-if="!experience.stillWorking">
                <label class="fs-14 fw-600 text__dark-grey mb-1x">
                  End Date
                </label>
                <Datepicker
                  v-model="experience.endDate"
                  name="End Date"
                  placeholder="End Date"
                  :disabled="!experience.startDate"
                  :disabled-dates="disabled_dates(index)"
                />
              </template>
            </div>
            <div class="col-6 m-col-12 flex flex-end z-24">
              <div class="flex flex__item-center">
                <span class="fs-14">Still working here</span>&nbsp;
                <NewToggle
                  :id="'still-working' + index"
                  v-model="experience.stillWorking"
                  :defaultValue="experience.stillWorking"
                />
              </div>
            </div>
            <div class="col-6" />
            <div class="col-6 m-col-12 u-above z-23">
              <label class="fs-14 fw-600 text__dark-grey mb-1x">
                Country
              </label>
              <Dropdown
                :options="countries"
                @selected="updateSelected($event, 'countryId', index)"
                :placeholder="countryLabel(experience)"
                :border="true"
              />
            </div>

            <div class="col-6 m-col-12 z-22">
              <label class="fs-14 fw-600 text__dark-grey mb-1x"> State </label>

              <Dropdown
                :options="experience.allStates"
                @selected="updateSelected($event, 'stateId', index)"
                :placeholder="cityLabel(experience)"
                :border="true"
              />
              <!-- <Dropdown
              :options="cities(experience)"
              @selected="updateSelected($event, 'stateId', index)"
              :placeholder="cityLabel(experience)"
              :border="true"
              :disabled="!isNumber(experience.countryId)"
            /> -->
            </div>
            <div class="col-6 m-col-12 z-21">
              <label class="fs-14 fw-600 text__dark-grey mb-1x">
                Work Type
              </label>
              <Dropdown
                :options="workTypes"
                @selected="updateSelected($event, 'workType', index)"
                :placeholder="workTypeLabel(experience)"
                :border="true"
              />
            </div>
            <div class="col-6">
              <label class="fs-14 fw-600 text__dark-grey mb-1x">
                Industry
              </label>
              <Dropdown
                :options="industryList"
                @selected="updateSelected($event, 'industry', index)"
                :placeholder="industryLabel(experience)"
                :border="true"
              />
            </div>

            <div class="col-12">
              <label class="fs-14 fw-600 text__dark-grey mb-1x">
                Description
              </label>
              <textarea
                placeholder="Add a description"
                class="h-12 p-2"
                v-model="experience.description"
                maxlength="1000"
              ></textarea>
              <div class="text-right">
                {{ experience.description.length }} / min(15)
              </div>
            </div>
          </div>

          <div class="flex flex__spaced">
            <Button
              :disabled="deleting"
              class="w-20"
              :loading="deleting"
              theme="red"
              @click="confirmDelete(experience)"
              btnType="button"
            >
              Delete
            </Button>
            <Button
              @click="updateExperience(experience.id)"
              class="w-20"
              theme="blue"
              :loading="updatingExperience"
              :disabled="
                updatingExperience ||
                invalid ||
                experience.description.length < 15
              "
            >
              Save Changes
            </Button>
          </div>
        </ValidationObserver>
      </Accordion>

      <div class="flex flex-end">
        <Button
          @click="addModal = true"
          class="w-20"
          theme="blue"
          :loading="addingExperience"
          :disabled="addingExperience"
        >
          Add New
        </Button>
      </div>
    </div>

    <NewModal v-model="addModal" target="modal-portal" size="wide">
      <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="">
          <h2 class="text-center">Add new Experience</h2>
          <div class="grid grid__layout grid__mobile gap-2 pb-3 mt-3">
            <Input
              class="col-6 m-col-12"
              type="text"
              placeholder="Company Name"
              label="Company Name"
              v-model="newExperience.companyName"
              name="Company Name"
              rules="required"
            />
            <div class="col-6 m-col-12 z-27">
              <label class="fs-14 fw-600 text__dark-grey mb-1x">
                Position
              </label>
              <Input
                type="text"
                placeholder="Position"
                v-model="newExperience.jobTitle"
                name="Position"
                rules="required"
              />
            </div>
            <div class="col-6 m-col-12 z-26">
              <label class="fs-14 fw-600 text__dark-grey mb-1x">
                Start Date
              </label>
              <Datepicker
                v-model="newExperience.startDate"
                name="Start Date"
                placeholder="Start Date"
                :disabled-dates="disableFuture"
              />
            </div>
            <div class="col-6 m-col-12 z-25">
              <template v-if="!newExperience.stillWorking">
                <label class="fs-14 fw-600 text__dark-grey mb-1x">
                  End Date
                </label>
                <Datepicker
                  v-model="newExperience.endDate"
                  name="End Date"
                  placeholder="End Date"
                  :disabled="!newExperience.startDate"
                  :disabled-dates="disabled_new_experience_dates()"
                />
              </template>
            </div>
            <div class="col-6 m-col-12 flex flex-end z-24">
              <div class="flex flex__item-center">
                <span class="fs-14">Still working here</span>&nbsp;
                <NewToggle
                  id="still-working-new"
                  v-model="newExperience.stillWorking"
                  :defaultValue="newExperience.stillWorking"
                />
              </div>
            </div>
            <div class="col-6" />
            <div class="col-6 m-col-12 u-above z-23">
              <label class="fs-14 fw-600 text__dark-grey mb-1x">
                Country
              </label>
              <Dropdown
                :options="countries"
                @selected="updateNewExperience($event, 'countryId')"
                :placeholder="countryLabel(newExperience)"
                :border="true"
              />
            </div>

            <div class="col-6 m-col-12 z-22">
              <label class="fs-14 fw-600 text__dark-grey mb-1x"> State </label>
              <Dropdown
                :options="newExperience.allStates"
                @selected="updateNewExperience($event, 'stateId')"
                :placeholder="cityLabel(newExperience)"
                :border="true"
              />
            </div>
            <div class="col-6 m-col-12 z-21">
              <label class="fs-14 fw-600 text__dark-grey mb-1x">
                Work Type
              </label>
              <Dropdown
                :options="workTypes"
                @selected="updateNewExperience($event, 'workType')"
                :placeholder="workTypeLabel(newExperience)"
                :border="true"
              />
            </div>
            <div class="col-6">
              <label class="fs-14 fw-600 text__dark-grey mb-1x">
                Industry
              </label>
              <Dropdown
                :options="industryList"
                @selected="updateNewExperience($event, 'industry')"
                placeholder="Industry"
                :border="true"
              />
            </div>

            <div class="col-12">
              <label class="fs-14 fw-600 text__dark-grey mb-1x">
                Description
              </label>
              <textarea
                placeholder="Add a description"
                class="h-12 p-2"
                v-model="newExperience.description"
              ></textarea>
              <div class="text-right">
                {{ newExperience.description.length }} / min(15)
              </div>
            </div>
          </div>

          <div class="flex flex-end">
            <Button
              @click="addNewExperience"
              class="w-20"
              theme="blue"
              :loading="addingExperience"
              :disabled="addingExperience || invalid || invalidNewExperience"
            >
              Add
            </Button>
          </div>
        </form>
      </ValidationObserver>
    </NewModal>

    <NewModal v-model="deleteModal" target="modal-portal">
      <h2 class="text-center mt-3">
        Are you sure? You are going to delete - <br />
        '{{ selectedExperience.jobTitle }} -
        {{ selectedExperience.companyName }}'
      </h2>
      <div class="flex flex__spaced mt-5">
        <Button class="w-20" theme="red" @click="deleteNow"> Yes </Button>
        <Button class="w-20" theme="blue" @click="deleteModal = false">
          No
        </Button>
      </div>
    </NewModal>
  </div>
</template>

<script>
// import NewToggle from "../../components/NewToggle.vue.js";
import SelectionBox from '@/components/SelectionBox';
import Accordion from '@/components/Accordion';
import { countriesAndStates } from '@/utils/countries';
import Datepicker from 'vuejs-datepicker';
import Toggle from '@/components/Toggle';
import { mapActions } from 'vuex';
import API from '@/const';
import jobseekerService from '../../services/api/jobseekerservice';
import publicService from '../../services/api/publicService';
import authService from '../../services/api/authService';
import seekerService from '../../services/api/seekerService';

export default {
  name: 'Skills',
  props: {
    profileData: {
      type: Object
    }
  },
  components: {
    SelectionBox,
    Accordion,
    Datepicker,
    Toggle
  },
  data() {
    return {
      industryList: [],
      countries: [],
      addModal: false,
      keySkills: {
        job_type: '',
        workType: ''
      },
      experiences: [],
      skills: [],
      jobTypes: [
        { name: 'Full Time', label: 'Full Time' },
        { name: 'Part Time', label: 'Part Time' }
      ],
      workTypes: [
        { name: 'On Site', label: 'On Site' },
        { name: 'Hybrid', label: 'Hybrid' },
        { name: 'Remote', label: 'Remote' }
      ],
      updatingSkills: false,
      positions: [
        { name: 'Sr. Sales Manager', value: 'sales_manager' },
        { name: 'Fashion Designer', value: 'fashion_designer' },
        { name: 'Sr. Admin', value: 'sr_admin' },
        {
          name: 'Sr. Marketing Executive',
          value: 'sr_marketing_executive'
        }
      ],
      updatingExperience: false,
      cities: [],
      newExperience: {
        companyName: '',
        jobTitle: '',
        workType: '',
        // salary: 0,
        stillWorking: false,
        startDate: '',
        industry: '',
        endDate: '',
        description: '',
        stateId: 0,
        countryId: 0,
        allStates: [],
        industry: ''
      },
      addingExperience: false,
      deleteModal: false,
      deleting: false,
      selectedExperience: {}
    };
  },
  created() {
    // this.countries = countries;

    // console.log(this.profileData);
    this.getAllCountries();
    this.getIndustryList();

    this.experiences = this.profileData.experiences.map((exp) => ({
      companyName: exp.companyName ?? '',
      id: exp.id,
      startDate: exp.startDate,
      endDate: exp.endDate,
      description: exp.description ?? '',
      stillWorking: exp.stillWorking ?? '',
      jobTitle: exp.jobTitle ?? '',
      countryId: exp.country.id ?? '',
      stateId: exp.state.id ?? '',
      workType: exp.workType ?? '',
      industry: exp.industry
    }));

    this.experiences.forEach((experience) => {
      this.getCities(experience);
    });

    this.keySkills.skills = this.profileData.profile.skills.map((skill) => ({
      name: skill,
      label: skill
    }));

    // // console.log("kkkk");
    // // console.log(this.keySkills);

    // const typesKeys = ["job_type", "workType"];

    // typesKeys.forEach((key) => {
    //   this.keySkills[key] = this.profileData.profile[key].map((type) => ({
    //     name: type.replace("_", " "),
    //     label: type
    //   }));
    // });
  },
  computed: {
    invalidNewExperience() {
      return (
        this.newExperience.stateId == 0 ||
        this.newExperience.countryId == 0 ||
        !this.newExperience.workType ||
        this.newExperience.description.length < 15
      );
    },
    disableFuture() {
      return {
        ranges: [
          {
            from: new Date(),
            to: new Date(3000, 11, 30)
          }
        ]
      };
    }
  },
  methods: {
    ...mapActions({
      $updateKeySkills: 'apimodel/makeRequest',
      $updateExperience: 'apimodel/makeRequest'
    }),
    getCities(experience) {
      console.log(experience);
      experience.allStates = [];
      publicService
        .getCitiesOfACountry(experience.countryId)
        .then((res) => {
          console.log(res.data);
          experience.allStates = res.data.country.states.map((state) => ({
            ...state,
            value: state.name
          }));

          setTimeout(() => {
            this.$forceUpdate();
          }, 555);

          this.$forceUpdate();
        })
        .catch((e) => {
          this.$handleError(e);
        });
    },
    countryLabel(experience) {
      const country = this.countries.find(
        (country) => country.id === experience.countryId
      );
      return country?.name || 'Country';
    },
    cityLabel(experience) {
      if (!experience.allStates) {
        return 'City';
      }

      const cl = experience.allStates.find(
        (city) => experience.stateId === city.id
      );
      return cl?.name || 'City';
    },
    workTypeLabel(experience) {
      if (!experience.workType) {
        return 'Work Type';
      }
      return experience.workType || 'Wob Type';
    },

    industryLabel(experience) {
      if (!experience.industry) {
        return 'Industry';
      }
      return experience.industry || 'Industry';
    },

    updateSelected(evt, key, index) {
      const { value, id = '' } = evt;

      if (key === 'countryId') {
        this.experiences[index].countryId = id;
        this.getCities(this.experiences[index]);
      } else if (key === 'stateId') {
        this.experiences[index].stateId = id;
      } else if (key === 'workType') {
        this.experiences[index][key] = evt.name;
      } else if (key === 'industry') {
        this.experiences[index][key] = evt.name;
      } else {
        this.experiences[index][key] = value;
      }
    },

    updateNewExperience(evt, key) {
      const { value, id = '' } = evt;
      //   return;

      if (key === 'countryId') {
        this.newExperience.countryId = id;
        this.getCities(this.newExperience);
      } else if (key === 'stateId') {
        this.newExperience.stateId = id;
      } else if (key === 'workType') {
        this.newExperience[key] = evt.name;
      } else if (key === 'industry') {
        this.newExperience[key] = evt.name;
      } else {
        // console.log(key, evt);
        this.newExperience[key] = value;
      }
    },
    disabled_dates(index) {
      const date = this.experiences[index].startDate
        ? [
            new Date(this.experiences[index].startDate).getFullYear(),
            new Date(this.experiences[index].startDate).getMonth(),
            new Date(this.experiences[index].startDate).getDate() + 1
          ]
        : [new Date()];

      return {
        ranges: [
          {
            from: new Date(1, 1, 1),
            to: new Date(...date)
          },
          {
            from: new Date(),
            to: new Date(3000, 11, 30)
          }
        ]
      };
    },
    disabled_new_experience_dates() {
      const sd = this.newExperience.startDate;
      const date = sd
        ? [
            new Date(sd).getFullYear(),
            new Date(sd).getMonth(),
            new Date(sd).getDate() + 1
          ]
        : [new Date()];

      return {
        ranges: [
          {
            from: new Date(1, 1, 1),
            to: new Date(...date)
          },
          {
            from: new Date(),
            to: new Date(3000, 11, 30)
          }
        ]
      };
    },

    isNumber(value) {
      return typeof value === 'number';
    },
    updateSelection(key, data) {
      this.keySkills[key] = data.map((val) => val.label);
    },
    updateKeySkills() {
      console.log(this.keySkills);
      //   const postData = this.keySkills.skills;
      this.updatingSkills = true;
      seekerService
        .updateSkills({ skills: this.keySkills.skills })
        .then((res) => {
          this.$handleSuccess(res);
        })
        .catch((e) => {
          this.$handleError(e);
        })
        .finally(() => {
          this.updatingSkills = false;
        });

      //   try {
      //     this.updatingSkills = true;
      //     const { data } = await this.$updateKeySkills({
      //       url: API.KEY_SKILLS,
      //       type: "post",
      //       data: { ...this.keySkills }
      //     });
      //     if (!data.error) {
      //       this.$notify({
      //         type: "success",
      //         title: "Success",
      //         text: "Skills updated successfully"
      //       });
      //       return;
      //     }
      //     this.$notify({
      //       type: "error",
      //       title: "Error",
      //       text: data.message || "Something went wrong"
      //     });
      //   } catch (error) {
      //     return error;
      //   } finally {
      //     this.updatingSkills = false;
      //   }
    },
    updateExperience(id) {
      const experience = this.experiences.find((exp) => exp.id === id);
      //   console.log(experience);

      this.updatingExperience = true;
      seekerService
        .updateExperience(experience)
        .then((res) => {
          this.$handleSuccess(res);
        })
        .catch((e) => {
          this.$handleError(e);
        })
        .finally(() => {
          this.updatingExperience = false;
        });
    },

    addNewExperience() {
      // console.log(this.newExperience);

      let postData = JSON.parse(JSON.stringify(this.newExperience));
      delete postData.allStates;

      this.addingExperience = true;
      seekerService
        .addNewExperience(postData)
        .then((res) => {
          this.$handleSuccess(res);
          this.$emit('on-success', { tab: 'Skills' });
          this.addModal = false;
        })
        .catch((e) => {
          this.$handleError(e);
        })
        .finally(() => {
          this.addingExperience = false;
        });
    },
    confirmDelete(experience) {
      this.selectedExperience = experience;
      this.deleteModal = true;
    },
    deleteNow() {
      this.deleteModal = false;
      this.deleting = true;
      seekerService
        .deleteExperience(this.selectedExperience.id)
        .then((res) => {
          this.$handleSuccess(res);
          this.$emit('on-success', { tab: 'Skills' });
        })
        .catch((e) => {
          this.$handleError(e);
        })
        .finally(() => {
          this.deleting = false;
        });
      //   jobseekerService
      //     .deleteExperience(this.selectedExperience.id)
      //     .then((res) => {
      //       if (res.data.error) {
      //         throw new Error(res.data.message);
      //         return;
      //       }

      //       this.$notify({
      //         type: "success",
      //         title: "Success",
      //         text: res.data.message || "Success"
      //       });

      //       this.$emit("on-success", { tab: "Education" });
      //     })
      //     .catch((e) => {
      //       this.$notify({
      //         type: "error",
      //         title: "Error",
      //         text: e.message || e.response.data.message || e
      //       });
      //     })
      //     .finally(() => {
      //       this.deleting = false;
      //     });
    },
    getAllCountries() {
      authService
        .getAllCountries()
        .then((res) => {
          this.countries = res.data.countries;
        })
        .catch()
        .finally();
    },
    getIndustryList() {
      publicService
        .getIndustyList()
        .then((res) => {
          //   this.countries = res.data.countries;
          //   console.log(res.data);
          // TODO: set real industry from api
          this.industryList = res.data.industries.map((industry) => ({
            name: industry.title,
            label: industry.title
          }));
          // this.industryList = [
          //   { name: "Industry type1", label: "Industry type1" },
          //   { name: "Industry type2", label: "Industry type2" },
          //   { name: "Industry type3", label: "Industry type3" }
          // ];
        })
        .catch()
        .finally();
    }
  },
  mounted() {
    publicService
      .getSkillsList()
      .then((res) => {
        this.skills = res.data.skills.map((skill) => ({
          name: skill.title,
          label: skill.title
        }));

        console.log(this.skills);
      })
      .catch((e) => {
        this.$handleError(e);
      })
      .finally(() => {});
  }
};
</script>

<style scoped lang="scss">
.mb-1x {
  margin-bottom: 8px;
  display: inline-block;
}
</style>
